import axios from 'axios'
import React,{useState} from 'react'
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet'
import ausbg from '../assets/img/europebg.jpg'
import Us from '../assets/flags/US.png'
import Can from '../assets/flags/Canada.png'
import Eur from '../assets/flags/europe.jpg'
import Ger from '../assets/flags/Germany.jpg'
import Aus from '../assets/flags/Australia.png'



export default function AustraliaLanding() {
  
const navigate = useNavigate();
    
  const formurl="https://backend.globedwise.com/helpDesk"
  const [formData , setformData] = useState({
    firstname:'',
    phone:'',
    emailaddress:'',
    querytype:'',
    feedbacktext:''
  });

  type formData = {
    firstname: string;
    emailaddress: string;
    phone: string;
    querytype: string;
  };

const handleglobeInput = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
  const { name, value } = e.target;
  const newData = { ...formData, [name]: value };
  setformData(newData);
  setShowMessage(false);
};

  

  const [showMessage, setShowMessage] = useState(false);

  const isValidEmail = (email: string) => {
    // Basic email validation 
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleSubmit=(e: { preventDefault: () => void; })=>{
    e.preventDefault();
    if (
      formData.firstname === '' ||
      !isValidEmail(formData.emailaddress) || 
      formData.phone.length !== 10 ) 
      {
      setShowMessage(true);
      return;
    }

    axios.post(formurl,{
    data : [
      {
          Name: formData.firstname,
          Mobile: formData.phone,
          Email_ID: formData.emailaddress,
          Type_of_Query_Feedback: formData.querytype,
          Describe_your_Query_Feedback: formData.feedbacktext,
          Lead_Stage: "New"
      }
  ],
  trigger : [ "approval", "workflow", "blueprint"],
  lar_id : "3654871000006222333"
  }
    
    )
    .then((response)=>{
      setformData({
          firstname:'',
          emailaddress:'',
          phone:'',
          querytype:'',
          feedbacktext:''
      });
      // alert('Your Form is Submitted')
      console.log(response);
      alert('Your form is submitted')
      navigate('/thankyou');
    })
    .catch((error) => {
      console.log(error);
      // Handle error and display appropriate message to the user
    });
  }
  return (
    <>
    <Helmet>
        <title>Empower Your Journey with Globedwise </title>
        <meta name="description" content="Embark on Your Global Education Adventure with Globedwise" />
        <meta name="google-site-verification" content="_AVkTsAnt6UJmKSzOeFHzxvpuxb4-Bsak9Y9_iKw5_k" />
    </Helmet>
    {/* <Carousel/> */}

        {/* hero section start */}
        <div id='#customcontainer' className="customcontainer mt-2 pt-2">
<div className="bg-image d-flex justify-content-center mt-5  align-items-center" style={{backgroundImage: `url(${ausbg})`,height:'530px',backgroundSize:'cover', backgroundPosition:'center', backgroundColor:'rgba(0, 0, 0, 0.5)', backgroundBlendMode:'overlay'}}>

    <div className="row landingformlayout w-100 mx-0 mt-3 align-items-center">
    <div className="col-md-8 ieltslandingcontent">
     <h1 className="bannerHeading text-light display-4 ">We Value Your Feedback .</h1>
     <ul className="bannerPoints text-dark">
     <li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Globedwise visualizes the dream of changing the approach for overseas education.</li>
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Share your distinctive experience to highlight  what's working well and identify  areas that could be enhanced</li>
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Make a tangible  difference with your valuable insights and feedback</li>
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Enhance your own and others experience  by sharing your thoughts </li>
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Your feedback fuels a cycle of continuous improvement. </li>
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Let your Dream becomes a Reality - Ease & Expertise of Overseas Education </li>

		</ul>
    <div className="row">
    <img src={Us} className='mt-2 mx-1' style={{width:'110px'}}/>
    <img src={Can} className='mt-2 mx-1' style={{width:'110px'}}/>
    <img src={Eur} className='mt-2 mx-1' style={{width:'110px'}}/>
    <img src={Aus} className='mt-2 mx-1' style={{width:'110px'}}/>
    <img src={Ger} className='mt-2 mx-1' style={{width:'110px'}}/>
    </div>   
     </div>

     <div className="ieltslandingcontentmobile" style={{display:'none'}}>
<h1 className='text-light text-center'>We Value Your Feedback</h1>
<p className='text-capitalize text-light text-center'>Your concern will be resolved within 24 hours</p>
{/* <div className="row justify-content-center mb-2">
    <img src={Us} className='mx-1' style={{width:'75px'}}/>
    <img src={Can} className='mx-1' style={{width:'75px'}}/>
    <img src={Ger} className='mx-1' style={{width:'75px'}}/>
    <img src={Aus} className='mx-1' style={{width:'75px'}}/>
    </div>  */}
</div>


     <div className="col-md-4 pb-0 px-3" >
            <div className="formhead bg-danger pt-3 pb-2 px-3">
              <h5 className='text-light text-capitalize'>Provide your feedback here</h5>
            </div>
        <div className="landingform bg-white shadow py-3 px-3">
        {showMessage && (
            <div className="alert alert-danger" role="alert">
              Please fill in all fields.
            </div>
        )}
        <form>
              <div className="row">
                <div className="form-outline mb-1 col-md-6">
                <label className="form-label fw-bold " style={{marginLeft: "0px"}}>First Name *</label>
                <input required type="text" name="firstname" className="form-control rounded-3 input-light2" onChange={(e)=> handleglobeInput(e)} defaultValue={formData.firstname} placeholder='Enter Your First name' tabIndex={0}/>
                <div className="form-notch"><div className="form-notch-leading" style={{width: "9px"}}></div><div className="form-notch-middle" ></div><div className="form-notch-trailing"></div></div></div>

                <div className="form-outline mb-1 col-md-6">
                <label className="form-label fw-bold " style={{marginLeft: "0px"}}>Mobile No. *</label>
                <input required type="number" name="phone" className="form-control rounded-3 input-light2" onChange={(e)=> handleglobeInput(e)} defaultValue={formData.phone} placeholder='Enter Your Mobile No.' tabIndex={0}/>
                <div className="form-notch"><div className="form-notch-leading" style={{width: "9px"}}></div><div className="form-notch-middle" ></div><div className="form-notch-trailing"></div></div></div>
              </div>
                <div className="form-outline mb-1">
                <label className="form-label fw-bold " style={{marginLeft: "0px"}}>Email address *</label>
                <input required type="email" name="emailaddress" className="form-control rounded-3 input-light2" onChange={(e)=> handleglobeInput(e)} defaultValue={formData.emailaddress} placeholder='Enter Your Email Address' tabIndex={0}/>
                <div className="form-notch"><div className="form-notch-leading" style={{width: "9px"}}></div><div className="form-notch-middle" ></div><div className="form-notch-trailing"></div></div></div>

                <div className="form-outline">
                <label className="form-label fw-bold " style={{marginLeft: "0px"}}>Feedback Related to *</label>
                  <select className="form-select rounded-3 input-light2" name='querytype' value={formData.querytype} onChange={(e)=> handleglobeInput(e)}>
                    <option value='Please select'>Please Provide Your Feedback</option>
                    <option value='General Quiry'>General Quiry</option>
                    <option value='Counseling'>Counseling</option>
                    <option value='Coaching'>Coaching</option>
                    <option value='Admissions'>Admissions</option>
                    <option value='SOP'>SOP</option>
                    <option value='Visa Process'>Visa Process</option>
                    <option value='Education Loan'>Education Loan</option>
                    <option value='Travel Insurance'>Travel Insurance</option>
                  </select>
                </div>

                <div className="form-outline mb-1 mt-2">
                <label className="form-label fw-bold " style={{marginLeft: "0px"}}>Describe your Feedback</label>
                <textarea required name="feedbacktext" className="form-control rounded-3 bg-light2" onChange={(e)=> handleglobeInput(e)} defaultValue={formData.feedbacktext} tabIndex={0}/>
                <div className="form-notch"><div className="form-notch-leading" style={{width: "9px"}}></div><div className="form-notch-middle" ></div><div className="form-notch-trailing"></div></div></div>                
        
                <button type="submit" onClick={handleSubmit} className="btn btn-danger btn-block mt-2 shadow-sm rounded-3 w-100">Register Here</button>

              </form>
        </div>
        </div>
    </div>
 
</div>

 </div>
{/* hero section ended */}

{/* quick start */}
{/* <div className="mainquick">
        <div className="row px-3 py-3 mx-0 justify-content-center">
            <div className="col-md-3 d-flex flex-column justify-content-center">
            <h3 className='text-light'>Getting Started is Easy</h3>
            </div>

            <div className="col-md-4">
            <div className="quickline">
            <div className="rightChapterBlockIcon">
                        <ul>
                            <li><p><i className="fa fa-search" aria-hidden="true"></i></p>
                                <span>Schedule a Meeting</span>
                            </li>
                            <li><p><i className="fa fa-envelope" aria-hidden="true"></i></p>
                                <span>Enter Your Details</span>
                            </li>
                            <li><p><i className="fas fa-check" aria-hidden="true"></i></p>
                                <span>Get free counseling</span>
                            </li>
                        </ul>
                    </div>
    
            </div>
            </div>

            <div className="col-md-4 px-3 d-flex flex-column justify-content-center mobile-quickline">
            <h4 className="text-white text-center">Check Your IELTS Level Now !</h4>
            <p className="text-white mb-0 text-center"><a className="text-white" onClick={goToTop}> <u>Check Now</u></a> </p>
            </div>
        </div>
    </div> */}
{/* quick ended */}

{/* youtube */}
<div className="section-head col-sm-12 mt-5" id='students'>
          <h4><span>What Our Students</span> Think About Us</h4>
        </div>
<div className="studentssec">
    <div className='video-container'>
    <iframe 
        src='https://www.youtube.com/embed/_80SKPHdPS4'
        allow='autoplay; encrypted-media'
        title='video'
        width={250} height={150}/>
    </div>

    <div className='video-container'>
    <iframe 
        src='https://www.youtube.com/embed/so-BRgMuRUY'
        allow='autoplay; encrypted-media'
        title='video'
         width={250} height={150}/>
    </div>

    <div className='video-container'>
    <iframe 
        src='https://www.youtube.com/embed/UgU6rBJkDCU'
        allow='autoplay; encrypted-media'
        title='video'
         width={250} height={150}/>
    </div>
   
    <div className='video-container'>
    <iframe 
        src='https://www.youtube.com/embed/e4KehKAIXDo'
        allow='autoplay; encrypted-media'
        title='video'
         width={250} height={150}/>
    </div>
   
    <div className='video-container'>
    <iframe 
        src='https://www.youtube.com/embed/-T0ZTUvAHgw'
        allow='autoplay; encrypted-media'
        title='video'
         width={250} height={150}/>
    </div>
   
    
</div>

<div className='text-center'>
<button className="btn btn-danger rounded-3 shadow"><a target='_blank' href="https://www.youtube.com/@globedwisestudyabroad" className='text-light d-flex'><i className="fab fa-youtube fs-3 mx-2"></i> Subscribe To Our Youtube Channel</a></button>
</div>
{/* youtube */}

<div className="section-head col-sm-12 mt-5">
    <h4><span>Frequently Asked</span> Questions</h4>
</div>

<div className="container">


<div className="accordion" id="accordionExample">
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingOne">
      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
      What are the advantages of studying internationally?
      </button>
    </h2>
    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <strong>Studying abroad presents a myriad of advantages, including valuable industry exposure through internships and work opportunities, in-depth knowledge acquisition, the opportunity to earn an international degree, and the enhancement of career prospects with a globally recognized qualification.</strong> A key advantage lies in the hands-on exposure that enables students to comprehend diverse work cultures, foster practical skills, and cultivate a robust professional network. Furthermore, obtaining an international degree not only enhances academic credentials but also showcases a global perspective, greatly expanding career opportunities in our increasingly interconnected world.
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingTwo">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
      What are some most popular countries for studying abroad?
      </button>
    </h2>
    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      There are various countries that are widely regarded as excellent study abroad destinations. Some popular study abroad destinations include the United States, United Kingdom, Canada, Australia, and France. These countries have prestigious universities and educational institutions, providing a wide variety of academic programs, and an immersive cultural experience for international students. However, the Ideal study-abroad destination is determined by personal preferences, academic ambitions, and the specific field of study. 
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingThree">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
      Which exams are necessary to pursue education abroad?
      </button>
    </h2>
    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      The exams required to study abroad can vary depending on the country, university, and specific program you are applying to. Some most common exams include the IELTS (International English Language Testing System) for English proficiency, TOEFL (Test of English as a Foreign Language). For graduate-level programs specifically in the USA, the GRE (Graduate Record Examination) or GMAT (Graduate Management Admission Test) are often required.<strong>To ascertain the precise examinations needed for your desired study abroad destination, it is crucial to conduct thorough research and seek guidance from an experienced study abroad counselor.</strong> 
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingFour">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
      When can I begin the application process for study abroad programs?
      </button>
    </h2>
    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      Depending on the programme and university, the study abroad application process often begins many months in advance. It is advised that you conduct research and prepare for study abroad at least 6-12 months before your  start date. This will give you plenty of time to acquire all of the relevant documentation, such as transcripts and recommendation letters, take any required exams, such as language proficiency tests, and meet application deadlines. 
      Starting early ensures that you have a sufficient amount of time to concentrate on the application process and boosts your chances of getting into your desired study abroad program.
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingFive">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
      How do Globedwise's Pre-Departure Sessions Help students in the study abroad packing process ?
      </button>
    </h2>
    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <strong> As it involves preparing for an extended stay in a foreign place, packing for study abroad might be difficult. However, with the right direction and resources, the process can be greatly simplified.</strong> For instance, Globedwise hosts Pre-Departure sessions that help students get ready for their study abroad journey.These sessions provide valuable knowledge and guidance on various aspects of packing, including what essentials to bring, how to pack efficiently, and tips for managing luggage restrictions. In this session, students can obtain practical insights and receive assistance in making well-informed packing choices. This enables them to pack confidently and ensure they have everything they need for a successful study abroad expedition.
      </div>
    </div>
  </div>
</div>

</div>

    
    </>
  )
}

import './App.css';
import './assets/css/bootstrap.min.css'
import { BrowserRouter  as Router ,  Routes , Route } from 'react-router-dom';
import React,{useState} from 'react';
import NewHeadercopy from './components/includes/NewHeadercopy';
import Mobileheadernew from './components/includes/Mobileheadernew';
import Footer from './components/includes/Footer';
import Thankyoupage from './components/Thankyoupage';
import AustraliaLanding from './components/LoanLanding';
import LoanLanding from './components/LoanLanding';

function App() {

  return (

    
  <>
  <Router>
      <NewHeadercopy />
      <Mobileheadernew />
      <Routes>
        <Route path='/' element={<LoanLanding/>}></Route>
        <Route path='/thankyou' element={<Thankyoupage/>}></Route>
      </Routes>
      <Footer />
    </Router>
    
    </>
   





  );
}

export default App;

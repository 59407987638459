import React from 'react';
import { Link, NavLink,useNavigate } from "react-router-dom";
import axios from 'axios'
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
// modal import
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
  } from 'mdb-react-ui-kit';
  // modal import

export default function Mobileheadernew() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [basicModal, setBasicModal] = useState(false);

    const toggleShow = () => setBasicModal(!basicModal);
    const navigate = useNavigate();

    const formurl="https://backend.globedwise.com/eventLead"
    const [formData , setformData] = useState({
      firstname:'',
      lastname:'',
      emailaddress:'',
      phone:''
    });
  
    type formData = {
      firstname: string;
      lastname: string;
      emailaddress: string;
      phone: string;
    };
  
    const handleglobeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      const newData = { ...formData, [name]: value };
      setformData(newData);
      setShowMessage(false);
    };
  
    const [showMessage, setShowMessage] = useState(false);
  
    const isValidEmail = (email: string) => {
      // Basic email validation 
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    };
  
    const handleSubmit=(e: { preventDefault: () => void; })=>{
      e.preventDefault();
      if (
        formData.firstname === '' ||
        formData.lastname === '' ||
        !isValidEmail(formData.emailaddress) || 
        formData.phone.length !== 10 ) 
        {
        setShowMessage(true);
        return;
      }
  
      axios.post(formurl,{
      data : [
        {
            First_Name: formData.lastname,
            Name: formData.firstname,
            Email: formData.emailaddress,
            Mobile: formData.phone,
            Event_Lead_Source: "Loan Landing Page"
        }
    ],
    trigger : [ "approval", "workflow", "blueprint"],
    lar_id : "3654871000006222333"
    }
      
      )
      .then((response)=>{
        setformData({
            firstname:'',
            lastname:'',
            emailaddress:'',
            phone:''
        });
        // alert('Your Form is Submitted')
        console.log(response);
        alert('Your form is submitted')
        navigate('/thankyou');
      })
      .catch((error) => {
        console.log(error);
        // Handle error and display appropriate message to the user
      });
  
    }

  return (
    <>
   <MDBModal show={basicModal} setShow={setBasicModal} tabIndex='-1' className="mt-5">
        <MDBModalDialog className="modal-dialog-centered">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle className="text-primary">Fill In Your Details</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
            <form>
                <div className="form-outline mb-1">
                <label className="form-label" htmlFor="form5Example1" style={{marginLeft: "0px"}}>First Name</label>
                  <input type="text" name="firstname" className="form-control" onChange={(e)=> handleglobeInput(e)} value={formData.firstname}/>
                <div className="form-notch"><div className="form-notch-leading" style={{width: "9px"}}></div><div className="form-notch-middle" style={{width: "42.4px"}}></div><div className="form-notch-trailing"></div></div></div>

                <div className="form-outline mb-1">
                <label className="form-label" htmlFor="form5Example1" style={{marginLeft: "0px"}}>Last Name</label>
                  <input type="text" name="lastname" className="form-control" onChange={(e)=> handleglobeInput(e)} value={formData.lastname}/>
                <div className="form-notch"><div className="form-notch-leading" style={{width: "9px"}}></div><div className="form-notch-middle" style={{width: "42.4px"}}></div><div className="form-notch-trailing"></div></div></div>

                <div className="form-outline mb-1">
                <label className="form-label" htmlFor="form5Example2" style={{marginLeft: "0px"}}>Email address</label>
                  <input type="email" name="emailaddress" className="form-control" onChange={(e)=> handleglobeInput(e)} value={formData.emailaddress}/>
                <div className="form-notch"><div className="form-notch-leading" style={{width: "9px"}}></div><div className="form-notch-middle" style={{width: "88.8px"}}></div><div className="form-notch-trailing"></div></div></div>

                <div className="form-outline mb-1">
                <label className="form-label" htmlFor="form5Example1" style={{marginLeft: "0px"}}>Phone No.</label>
                  <input type="number" name="phone" className="form-control" onChange={(e)=> handleglobeInput(e)} value={formData.phone}/>
                <div className="form-notch"><div className="form-notch-leading" style={{width: "9px"}}></div><div className="form-notch-middle" style={{width: "42.4px"}}></div><div className="form-notch-trailing"></div></div></div>

                {/* <div className="form-check d-flex mb-4">
                  <input className="form-check-input me-2" type="checkbox" value="" id="form5Example3"/>
                  <label className="form-check-label" htmlFor="form5Example3">
                    I have read and agree to the <a href="/">terms & conditions</a> 
                  </label>
                </div> */}

                <button type="submit" onClick={handleSubmit} className="btn btn-primary btn-block">Submit</button>
              </form>
            </MDBModalBody>

            
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

    <div className="outermobilehead sticky pb-3" style={{display:'none'}}>
    {/* <div className="col-md-12 bg-danger py-1" style={{display:'flex', justifyContent:'flex-end'}}>
    <p className="dropdown dropdown-toggle text-light mx-2 my-0" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          OUR SERVICES
          </p>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a className="dropdown-item my-0" target='_blank' href="https://events.globedwise.com/" onClick={handleClose}>Events</a></li>
            <li><hr className="dropdown-divider"/></li>
            <li><a className="dropdown-item my-0" href="/" onClick={handleClose}>Blogs</a></li>
            <li><hr className="dropdown-divider"/></li>
            <li><a className="dropdown-item my-0" href="/" onClick={handleClose}>Scholarship</a></li>
            <li><hr className="dropdown-divider"/></li>
            <li><a className="dropdown-item my-0" href="/" onClick={handleClose}>Check Your IELTS Level</a></li>
            <li><hr className="dropdown-divider"/></li>
            <a className="text-danger mx-3" target="_blank" onClick={toggleShow}>Get In Touch</a>
          </ul>
    </div> */}

<div className="col-md-12 shadow newmobilehead bg-white" style={{display:'none'}}>

<Link to="/"> <img src="https://canada.globedwise.com/assets/images/Logo%20PNG.png" className='mx-3' alt="" style={{width:'150px'}}/></Link>

{/* <Button variant="" onClick={handleShow}>
    <i className="fas fa-bars menu-toggle text-primary"></i>
</Button> */}

<div className="social2 py-3">
    <div className="socialcall mx-2 btn-sm btn-danger rounded-3 shadow"> 
    <p className="mb-0"><a href="tel:919696875875" className="text-light"> <i className="fa fa-phone-alt text-light" aria-hidden="true"></i> +91 9696875875</a></p>
    </div>
    </div>

</div>  

</div>
    </>
  )
}

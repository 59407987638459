import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // For smooth scrolling, you can omit this if you want instant scrolling
        });
    }
    return (
        <>

            <div className="container-fluid bg-light2 text-dark footer pt-5 mt-5 wow fadeIn" data-wow-delay="0.1s">


                <div className="container py-5">
                    <div className="row g-5">

                        <div className="col-lg-6 col-md-6">
                            <h4 className="text-primary mb-3">Our Locations</h4>
                            <p className="text-primary font-weight-bold"><i className="fa fa-map-marker-alt me-1 text-primary"></i>
                                <strong> Prashant Vihar, New Delhi </strong> <br />
                                <span className="text-dark">A-54, Prashant Vihar, Opposite Lancer Convent School, Sector - 14, Rohini,</span>
                            </p>
                            <p className="text-primary font-weight-bold"><i className="fa fa-map-marker-alt me-1 text-primary"></i>
                                <strong> Rajouri Garden, New Delhi</strong> <br />
                                <span className="text-dark">J-4/1, Rajouri Garden, New Delhi - 110027, India</span>
                            </p>
                            <p className="text-primary font-weight-bold"><i className="fa fa-map-marker-alt me-1 text-primary"></i>
                                <strong> Vijay Nagar, New Delhi </strong> <br />
                                <span className="text-dark"> H-15, Vijay Nagar, Delhi University Area, Delhi - 110009, India</span></p>
                            <p className="text-primary font-weight-bold"><i className="fa fa-map-marker-alt me-1 text-primary"></i>
                                <strong> Chandigarh,</strong> <br />
                                <span className="text-dark"> SCO 315-316, First Floor, Sector 35B, Chandigarh -160022, India</span>
                            </p>

                        </div>
                        <div className="col-lg-6 col-md-6 text-center">
                            <h2 className="text-primary my-3">Ease & Expertise Of <br />
                                Overseas Education</h2>

                            <Link to="https://www.globedwise.com/events" className="btn btn-dark btn-lg mx-2 my-2 fw-bold">Upcoming Events</Link>
                            <button onClick={scrollToTop} className="btn btn-primary btn-lg mx-2 my-2 fw-bold">Get In Touch</button>

                        </div>

                    </div>
                </div>
                <div className="container">
                    <div className="copyright">
                        <div className="row">
                            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                                &copy; <a className="border-bottom text-primary" href="javascript:void(0)">Copyright © 2024 GlobEDwise</a>, All Right Reserved.
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="bi bi-arrow-up"></i></a>




        </>
    );
}
